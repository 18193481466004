/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
==========================================================================================*/
const getters = {
  APP_NAME_VUE: state => state.app_name_vue,
  DEV_COMPANY_NAME: state => state.dev_company_name,
  DEV_COMPANY_URL: state => state.dev_company_url,
  DAYS: state => state.days,
  NOTIFICATION_TYPES: state => state.notificationtypes,
  LOGIN_FOOTER: state => state.login_footer,
  API_URL: state => state.apiURL + '/',
  CLIENT_URL: state => state.clientURL,
  LOGO_FILENAME: state => state.logoFilename,
  ICON_FILE: state => state.clientURL + state.iconFilename,
  TRANSLATIONS_CACHE_TIME: state => state.translationsCacheTime,
  APP_TRANSLATIONS_ENABLED: state => state.appTranslationsEnabled,
  TRANSLATIONS_CHANGE_DETECTED: state => state.translations_change_detected,
  DEFAULT_DECIMALS: state => state.defaultDecimals,

  USER_ID: state => state.AppActiveUser.id,
  // admin has groups_id = 5
  USER_IS_ADMIN: state => parseInt(state.AppActiveUser.groups_id) === 5,
  USER_IS_DEVELOPER: state => state.AppActiveUser.developer === 'Y',
  USER_FIRMS_ID: state => state.AppActiveUser.firms_id,
  USER_DETAIL: state => state.AppActiveUser,

  SHOW_FORM_LEVELS: state => state.showFormLevels,
  API_IN_ACTION: state => state.apiInAction,
  DEFAULT_DATE: state => state.default_date,
  DEFAULT_DATETIME: state => state.default_datetime,
  DEFAULT_YEAR: state => state.default_year,
  SIDEBAR_MINIMIZE: state => state.sidebarMinimize,
  SIDEBAR_SHOW: state => state.sidebarShow,

  // browser x width
  WINDOW_WIDTH: state => state.windowWidth,
  // browser scroll position
  SCROLL_Y: state => state.scrollY,
  // app theme (light x dark)
  APP_THEME: state => state.appTheme
};

export default getters;
