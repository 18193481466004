/*=========================================================================================
  File Name: admin.js
  Description: actions requests to admin.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'admin';

const state = {
  ipsBlocked: [],
  logins: [],
  firms: [],
  formFields: [],
  formFieldGroups: [],
  formFieldGroupsAll: [],
  formStructures: []
};

const getters = {
  USERS_LOGINS: state => {
    state.logins.forEach(login => {
      if (login.id == 0) login['_classes'] = 'text-danger';
    });

    return state.logins;
  },

  IPS_BLOCKED: state => {
    return state.ipsBlocked;
  },

  FIRMS: state => {
    return state.firms;
  },

  FIRM: state => id => {
    return state.firms.find(item => item.id == id);
  },

  FORM_FIELDS: state => {
    return state.formFields;
  },

  FORM_FIELD_GROUPS_ALL: state => {
    return state.formFieldGroupsAll;
  },

  FORM_FIELD_GROUPS_SELECT: state => {
    return Vue.$fnc.createSelectOptions(state.formFieldGroups);
  },

  FORM_STRUCTURES: state => {
    return state.formStructures;
  },

  FORM_STRUCTURES_SELECT: state => {
    return Vue.$fnc.createSelectOptions(state.formStructures);
  }
};

const mutations = {
  ADD_FIRM(state, payload) {
    // if new firm is already in firms array, exit
    if (Vue.$fnc.arrayFind(state.firms, 'id', payload.id)) {
      return false;
    }

    // else add new firm
    state.firms.push(payload);
  },

  UPDATE_FIRM_DATA(state, payload) {
    Vue.$fnc.updateArrayObject(state.firms, payload);
  },

  UPDATE_FIRM_WASTE_ACTIVE(state, payload) {
    let temp = Vue.$fnc.arrayFind(state.firms, 'id', payload.id);
    Vue.set(temp.waste_actives, [payload.countries_id], payload.waste_active);
  }
};

const actions = {
  users: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'users');

    commit('fetch', ['masterdata', 'users', data.data['users'], 'user'], {
      root: true
    });
    commit('fetch', [moduleName, 'logins', data.data['user_logins']], {
      root: true
    });
    commit('fetch', [moduleName, 'ipsBlocked', data.data['ips_blocked']], {
      root: true
    });

    return data;
  },

  'users-new': async () => {
    const data = await Vue.$api.index(moduleName, 'users-new');

    return data;
  },

  'user-detail': async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'user-detail', payload);

    return data;
  },

  'user-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'user-save', payload);

    return data;
  },

  'ip-blocked-remove': async (context, ip) => {
    await Vue.$api.delete(moduleName, 'ip-blocked-remove', { ip: ip });
  },

  firms: async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'firms', payload);

    commit('fetch', [moduleName, 'firms', data.data['firms'], 'firm'], {
      root: true
    });
    commit('fetch', ['masterdata', 'countries', data.data['countries']], {
      root: true
    });

    return data;
  },

  'firms-new': async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'firms-new');

    commit(
      'fetch',
      ['masterdata', 'representatives', data.data['representative_list']],
      { root: true }
    );

    commit('fetch', ['masterdata', 'firmTypes', data.data['firm_types']], {
      root: true
    });

    return data;
  },

  'firms-detail': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'firms-detail', payload);

    commit('fetch', ['masterdata', 'countries', data.data['countries']], {
      root: true
    });
    commit(
      'fetch',
      ['masterdata', 'representatives', data.data['representative_list']],
      { root: true }
    );

    return data;
  },

  'firms-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'firms-save', payload);

    return data;
  },

  'firms-user-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'firms-user-save', payload);

    return data;
  },

  'firms-generate-password': async (context, payload) => {
    const data = await Vue.$api.get(
      moduleName,
      'firms-generate-password',
      payload
    );

    return data;
  },

  'firms-contact-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'firms-contact-save',
      payload
    );

    return data;
  },

  'firms-contact-remove': async (context, payload) => {
    const data = await Vue.$api.delete(moduleName, 'firms-contact-remove', {
      id: payload.id
    });

    return data;
  },

  'firms-note-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'firms-note-save', payload);

    return data.saved;
  },

  'firms-note-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'firms-note-remove',
      payload
    );

    return data.removed;
  },

  'supplier-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'supplier-save', payload);

    return data.saved;
  },

  'supplier-remove': async (context, payload) => {
    const data = await Vue.$api.delete(moduleName, 'supplier-remove', payload);

    return data.removed;
  },

  'supplier-products': async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'supplier-products', payload);

    return data;
  },

  'supplier-product-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'supplier-product-save',
      payload
    );

    return data.saved;
  },

  'supplier-product-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'supplier-product-remove',
      payload
    );

    return data.removed;
  },

  formstructure: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'formstructure');

    commit('fetch', [moduleName, 'formStructures', data.data['list']], {
      root: true
    });
    commit('fetch', ['masterdata', 'levels', data.data['levels']], {
      root: true
    });
  },

  'formstructure-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'formstructure-save',
      payload
    );

    return data;
  },

  'formstructure-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'formstructure-remove',
      payload
    );

    return data;
  },

  formfields: async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'formfields', payload);

    commit('fetch', [moduleName, 'formFields', data.data['list']], {
      root: true
    });
    commit(
      'fetch',
      [moduleName, 'formStructures', data.data['formstructure']],
      { root: true }
    );
    commit(
      'fetch',
      [moduleName, 'formFieldGroups', data.data['formfield_groups']],
      { root: true }
    );
    commit(
      'fetch',
      [moduleName, 'formFieldGroupsAll', data.data['formfield_groups_all']],
      { root: true }
    );
    commit('fetch', ['masterdata', 'levels', data.data['levels']], {
      root: true
    });

    return data.data;
  },

  'formfields-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'formfields-save', payload);

    return data;
  },

  'formfields-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'formfields-remove',
      payload
    );

    return data;
  },

  notifications: async () => {
    const data = await Vue.$api.index(moduleName, 'notifications');

    return data.data;
  },

  languages: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'languages');

    commit('set', ['masterdata', 'languages', data.data['list']], {
      root: true
    });

    return data;
  },

  'language-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'language-save', payload);

    return data;
  },

  translations: async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'translations', {
      lang_id: payload.id
    });

    return data;
  },

  'translation-save': async ({ dispatch }, payload) => {
    const data = await Vue.$api.update(moduleName, 'translation-save', payload);

    // pro aktualizaci prekladu systemu
    dispatch('translations', null, { root: true });

    return data.saved;
  },

  'notification-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'notification-save',
      payload
    );

    return data;
  },

  notificationspool: async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'notificationspool', payload);

    return data;
  },

  'notificationspool-detail': async (context, payload) => {
    const data = await Vue.$api.index(
      moduleName,
      'notificationspool-detail',
      payload
    );

    return data;
  },

  'notificationspool-send': async ({ dispatch }, payload) => {
    const data = await Vue.$api.post(
      moduleName,
      'notificationspool-send',
      payload
    );

    if (data.saved && data.firm_id > 0) {
      // aktualizace casu last_notification seznamu firmy
      dispatch(
        'firms/updateFirmProductData',
        {
          id: data.firm_id,
          last_notification: data.last_notification,
          notifications: data.notifications
        },
        { root: true }
      );
    }

    return data.saved;
  },

  'notificationspool-mass-send': async ({ dispatch }, payload) => {
    const data = await Vue.$api.post(
      moduleName,
      'notificationspool-mass-send',
      payload
    );

    if (data.saved && !Vue.$fnc.isArrayEmpty(data['last'])) {
      // aktualizace casu last_notification seznamu firmy
      data['last'].forEach(item => {
        dispatch(
          'firms/updateFirmProductData',
          {
            id: item.firm_id,
            last_notification: item.last_notification,
            notifications: item.notifications
          },
          { root: true }
        );
      });
    }

    return data.saved;
  },

  'notificationspool-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'notificationspool-remove',
      payload
    );

    return data;
  },

  'notificationspool-bulk-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'notificationspool-bulk-remove',
      payload
    );

    return data;
  },

  'listed-items': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'listed-items', payload);

    commit(
      'fetch',
      ['masterdata', 'productHierarchy', data.data['product_hierarchy']],
      {
        root: true
      }
    );
    commit('fetch', ['masterdata', 'countries', data.data['countries']], {
      root: true
    });

    return data.data;
  },

  'listed-suppliers': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'listed-suppliers', payload);

    commit('fetch', ['masterdata', 'countries', data.data['countries']], {
      root: true
    });

    return data.data;
  },

  extremes: async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'extremes', payload);

    return data.data;
  },

  'extremes-products': async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'extremes-products', payload);

    return data.data;
  },

  'extremes-clipboard': async (context, payload) => {
    const data = await Vue.$api.index(
      moduleName,
      'extremes-clipboard',
      payload
    );

    return data.data;
  },

  'extremes-clipboard-save': async (context, payload) => {
    const data = await Vue.$api.post(
      moduleName,
      'extremes-clipboard-save',
      payload
    );

    return data;
  },

  'extremes-send': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'extremes-send', payload);

    return data.saved;
  },

  'completeness-recalc': async () => {
    await Vue.$api.post(moduleName, 'completeness-recalc');
  },

  'fulltext-products': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'fulltext-products', payload);

    commit('fetch', ['masterdata', 'countries', data.data['countries']], {
      root: true
    });

    return data.data;
  },

  import: async (context, payload) => {
    await Vue.$api.post(moduleName, 'import', payload);
  },

  // not api req
  // manually update data
  updateFirmData: async ({ commit }, payload) => {
    commit('UPDATE_FIRM_DATA', payload);
  },

  updateFirmWasteActive: async ({ commit }, payload) => {
    commit('UPDATE_FIRM_WASTE_ACTIVE', payload);
  },

  addFirm: async ({ commit }, payload) => {
    commit('ADD_FIRM', payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
